define("discourse/plugins/discourse-automation/discourse/helpers/format-enabled-automation", ["discourse-common/lib/icon-library", "discourse-common/lib/helpers", "@ember/template"], function (_iconLibrary, _helpers, _template) {
  "use strict";

  (0, _helpers.registerUnbound)("format-enabled-automation", function (enabled, trigger) {
    if (enabled && trigger.id) {
      return (0, _template.htmlSafe)((0, _iconLibrary.iconHTML)("check", {
        class: "enabled-automation",
        title: "discourse_automation.models.automation.enabled.label"
      }));
    } else {
      return (0, _template.htmlSafe)((0, _iconLibrary.iconHTML)("times", {
        class: "disabled-automation",
        title: "discourse_automation.models.automation.disabled.label"
      }));
    }
  });
});